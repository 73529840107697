import { polygon } from '@turf/turf';
import {
  AreaType,
  DeliveryAreas,
} from '@bufteam/cfacorp_delivery.bufbuild_es/cfa/delivery/area/v1/area_service_pb';

export const getTurfPolygon = co =>
  polygon([ensureClosedPolygon(co).map(c => [c.longitude, c.latitude])]);
const ensureClosedPolygon = co =>
  co.length > 0 &&
  (co[0]?.latitude !== co[co.length - 1]?.latitude ||
    co[0]?.longitude !== co[co.length - 1]?.longitude)
    ? [...co, { ...co[0] }]
    : co;
export const getCoordinates = (
  deliveryAreas: DeliveryAreas,
  areaType: AreaType,
) =>
  deliveryAreas?.areas?.find(a => a.areaType === areaType)?.coordinates ?? [];
