import { Coordinates } from '@bufteam/cfacorp_delivery.bufbuild_es/cfa/delivery/core/v1/coordinates_pb';
import {
  AreaType,
  DeliveryArea,
  TravelType,
  UpdateDeliveryAreasResponse,
} from '@bufteam/cfacorp_delivery.bufbuild_es/cfa/delivery/area/v1/area_service_pb';
import { useEffect } from 'react';
import {
  AppNames,
  publishCartDataPoints,
} from '@cfacorp/ctrl-platform-ui-core-utils';
import { filter } from 'rxjs';
import { useGetStoreNumber } from './useGetStoreNumber';

export const useOnPublishCart = ({
  updateDeliveryArea,
  draftCoordinates,
  travelType,
  resetDraft,
}: {
  updateDeliveryArea: (
    da: DeliveryArea,
    areaType: AreaType,
    isPublish?: boolean,
  ) => Promise<UpdateDeliveryAreasResponse>;
  draftCoordinates: Coordinates[];
  travelType: TravelType | null;
  resetDraft: () => void;
}) => {
  const storeNumber = useGetStoreNumber();

  useEffect(() => {
    const deleteSub = publishCartDataPoints
      .pipe(
        filter(
          cart =>
            cart.appName === AppNames.CFA_DELIVERY &&
            !!cart.dataPoints.length &&
            !!storeNumber.length,
        ),
      )
      .subscribe(() => {
        (async () => {
          await updateDeliveryArea(
            new DeliveryArea({
              travelType: travelType ?? TravelType.DRIVE,
              coordinates: draftCoordinates,
            }),
            AreaType.CURRENT,
            true,
          );
          // trigger useDraftArea to get latest data
          resetDraft();
          publishCartDataPoints.next({
            dataPoints: [],
            appName: null,
          });
        })();
      });
    return () => {
      deleteSub.unsubscribe();
    };
  }, [
    updateDeliveryArea,
    storeNumber.length,
    draftCoordinates,
    travelType,
    resetDraft,
  ]);
};
