import {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useContext,
  useState,
} from 'react';
import { NO_ESTIMATE_OPTION } from '../containers/DeliveryArea/utils';

interface TimeEstimateContextType {
  timeEstimate: number;
  setTimeEstimate: Dispatch<SetStateAction<number>>;
}

const defaultContextValue: TimeEstimateContextType = {
  timeEstimate: NO_ESTIMATE_OPTION,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setTimeEstimate: () => {},
};

const TimeEstimateContext =
  createContext<TimeEstimateContextType>(defaultContextValue);

export const useTimeEstimateContext = () => useContext(TimeEstimateContext);

export const TimeEstimateProvider = ({ children }: { children: ReactNode }) => {
  const [timeEstimate, setTimeEstimate] = useState<number>(NO_ESTIMATE_OPTION);

  return (
    <TimeEstimateContext.Provider value={{ timeEstimate, setTimeEstimate }}>
      {children}
    </TimeEstimateContext.Provider>
  );
};
