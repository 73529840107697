import { TravelType } from '@bufteam/cfacorp_delivery.bufbuild_es/cfa/delivery/area/v1/area_service_pb';
import { logEvent } from './amplitude';

export const events = {
  buttonTap: (btnName: string, locationNumber: string) => {
    logEvent({
      name: 'ButtonTap',
      attributes: { locationNumber, button: btnName },
    });
  },
  showOnMap: (placeId: string, locationNumber: string) => {
    logEvent({ name: 'ShowOnMap', attributes: { locationNumber, placeId } });
  },
  showTimeEstimate: (
    locationNumber: string,
    timeEstimate: number,
    travelMode: TravelType,
  ) => {
    logEvent({
      name: 'ShowTimeEstimate',
      attributes: { locationNumber, timeEstimate, travelMode },
    });
  },
  setAsDeliveryArea: (
    locationNumber: string,
    timeEstimate: number,
    travelMode: TravelType,
  ) => {
    logEvent({
      name: 'SetAsDeliveryArea',
      attributes: { locationNumber, timeEstimate, travelMode },
    });
  },
};
