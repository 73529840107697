import { Coordinates } from '@bufteam/cfacorp_delivery.bufbuild_es/cfa/delivery/core/v1/coordinates_pb';

// TODO: There is a bug where Dropdown component doesn't emit events if value is integer 0
// placed in a request ticket to fix
// using 86 as placeholder for now
// Creates an array of numbers 0-15 for time estimates
export const NO_ESTIMATE_OPTION = 86;
export const TIME_ESTIMATE_OPTIONS = [NO_ESTIMATE_OPTION, 6, 8, 10];
export function formatMinutes(number: number): string {
  const s = number > 1 ? 's' : '';
  return number === NO_ESTIMATE_OPTION ? 'None' : `${number} minute${s}`;
}

/**
 * Map Utility Functions
 */
// Translates delivery coordinates into google maps LatLng
export const getLatLng = (coords: Coordinates[]): google.maps.LatLng[] =>
  coords.map(c => new google.maps.LatLng(c.latitude, c.longitude));

export const displayMapMarker = (
  placeId: string,
  map: google.maps.Map,
  setter: (marker: google.maps.Marker) => void,
) => {
  new google.maps.places.PlacesService(map).getDetails(
    { placeId },
    (result, status) => {
      if (status === 'OK' && result) {
        const marker = new google.maps.Marker({
          position: result.geometry?.location,
        });
        setter(marker);
      } else {
        console.error(
          'Getting marker details for the address provided',
          status,
        );
      }
    },
  );
};

export const getOuterBounds = (): google.maps.LatLng[] => [
  new google.maps.LatLng(85, 180),
  new google.maps.LatLng(85, 90),
  new google.maps.LatLng(85, 0),
  new google.maps.LatLng(85, -90),
  new google.maps.LatLng(85, -180),
  new google.maps.LatLng(0, -180),
  new google.maps.LatLng(-85, -180),
  new google.maps.LatLng(-85, -90),
  new google.maps.LatLng(-85, 0),
  new google.maps.LatLng(-85, 90),
  new google.maps.LatLng(-85, 180),
  new google.maps.LatLng(0, 180),
  new google.maps.LatLng(85, 180),
];
