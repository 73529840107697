import { Coordinates } from '@bufteam/cfacorp_delivery.bufbuild_es/cfa/delivery/core/v1/coordinates_pb';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { AreaType } from '@bufteam/cfacorp_delivery.bufbuild_es/cfa/delivery/area/v1/area_service_pb';
import { getLatLng, getOuterBounds } from '../containers/DeliveryArea/utils';
import { colors } from '../theme';
import { useDeliveryAreas } from './useDeliveryAreas';
import { useGetStoreNumber } from './useGetStoreNumber';
import { getCoordinates } from './utils';
import useIsDeliveryAdmin from './useIsDeliveryAdmin';

export const useMaxArea = ({
  map,
}: {
  map: google.maps.Map | null;
}): {
  setIsMaxAreaEdited: (b: boolean) => void;
  maxAreaCoordinates?: Coordinates[];
  isMaxAreaEdited: boolean;
  resetMaxArea: () => void;
} => {
  const { deliveryAreas } = useDeliveryAreas();
  const storeNumber = useGetStoreNumber();
  const { isDeliveryAdmin } = useIsDeliveryAdmin();
  const maxPolygon = useMemo(
    () =>
      new google.maps.Polygon({
        paths: [getLatLng([]), getOuterBounds()],
        fillColor: colors.gray7,
        fillOpacity: 0.5,
        strokeWeight: 0,
      }),
    [],
  );
  const [isMaxAreaEdited, setIsMaxAreaEdited] = useState<boolean>(false);
  const [isListening, setIsListening] = useState<boolean>(false);
  const [maxAreaCoordinates, setMaxAreaCoordinates] = useState<Coordinates[]>(
    [],
  );

  useEffect(() => {
    if (storeNumber?.length && deliveryAreas) {
      const maxCoords = getCoordinates(deliveryAreas, AreaType.MAX);
      setMaxAreaCoordinates(maxCoords);
      maxPolygon.setPaths([getLatLng(maxCoords ?? []), getOuterBounds()]);
    }
  }, [maxAreaCoordinates, storeNumber, maxPolygon, deliveryAreas]);

  const updateCoordinates = useCallback(
    (p: google.maps.Polygon | null) =>
      p &&
      setMaxAreaCoordinates(
        p
          .getPath()
          .getArray()
          .map(
            (ll: google.maps.LatLng) =>
              new Coordinates({ longitude: ll.lng(), latitude: ll.lat() }),
          ),
      ),
    [],
  );

  const addPolygonListeners = useCallback(() => {
    if (
      maxPolygon?.getPath()?.getArray()?.length &&
      !isListening &&
      isDeliveryAdmin
    ) {
      setIsListening(true);
      const path = maxPolygon.getPath();
      google.maps.event.addListener(path, 'insert_at', () => {
        updateCoordinates(maxPolygon);
        setIsMaxAreaEdited(true);
      });
      google.maps.event.addListener(path, 'set_at', () => {
        updateCoordinates(maxPolygon);
        setIsMaxAreaEdited(true);
      });
    }
  }, [maxPolygon, isListening, updateCoordinates, isDeliveryAdmin]);

  useEffect(() => addPolygonListeners(), [addPolygonListeners]);

  // if user is an admin, then set as editable
  // not editable if user not an admin
  useEffect(
    () => maxPolygon?.setEditable(isDeliveryAdmin),
    [isDeliveryAdmin, maxPolygon],
  );

  // Set map if not already set
  useEffect(() => {
    maxPolygon.setMap(map);
  }, [map, maxPolygon]);

  const resetMaxArea = useCallback(() => {
    setMaxAreaCoordinates([]);
    setIsMaxAreaEdited(false);
    maxPolygon?.setPath([]);
    setIsListening(false);
  }, [maxPolygon]);

  return {
    setIsMaxAreaEdited,
    maxAreaCoordinates,
    isMaxAreaEdited,
    resetMaxArea,
  };
};
