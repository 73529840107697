import { useEffect, useState } from 'react';
import {
  useShowAdminActions,
  useUserPermissions,
} from '@cfacorp/ctrl-platform-shared-react-components';

const useIsDeliveryAdmin = () => {
  const cfaPerms = useUserPermissions();
  const showAdminActions = useShowAdminActions();
  const [isDeliveryAdmin, setIsDeliveryAdmin] = useState(false);
  const [permsLoaded, setPermsLoaded] = useState(false);

  useEffect(() => {
    if (cfaPerms) {
      const isAdmin =
        !!cfaPerms.SP_CONFIG?.SUPER_ADMIN?.length ||
        !!cfaPerms.SP_CONFIG?.DELIVERY_ADMIN?.length;
      setIsDeliveryAdmin(isAdmin);
      setPermsLoaded(true);
    }
  }, [cfaPerms]);

  return {
    isDeliveryAdmin: isDeliveryAdmin && showAdminActions,
    permsLoaded,
  };
};

export default useIsDeliveryAdmin;
