import {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useContext,
  useState,
} from 'react';
import { TravelType } from '@bufteam/cfacorp_delivery.bufbuild_es/cfa/delivery/area/v1/area_service_pb';

interface TravelTypeContextType {
  travelType: TravelType;
  setTravelType: Dispatch<SetStateAction<TravelType>>;
}

const defaultContextValue: TravelTypeContextType = {
  travelType: TravelType.DRIVE,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setTravelType: () => {},
};

const TravelTypeContext =
  createContext<TravelTypeContextType>(defaultContextValue);

export const useTravelTypeContext = () => useContext(TravelTypeContext);

export const TravelTypeProvider = ({ children }: { children: ReactNode }) => {
  const [travelType, setTravelType] = useState<TravelType>(TravelType.DRIVE);

  return (
    <TravelTypeContext.Provider value={{ travelType, setTravelType }}>
      {children}
    </TravelTypeContext.Provider>
  );
};
