import { lazy, Suspense, useEffect, useMemo } from 'react';
import './i18n';
import {
  appLoadingStatuses,
  AppNames,
  cartDataPointsGroups,
  FeatureFlags,
  globalRoutes,
  userPermissions,
} from '@cfacorp/ctrl-platform-ui-core-utils';
import RouteInfo from '@cfacorp/ctrl-platform-ui-core-utils/dist/types/RouteInfo';
import { useHasFeatureFlag } from '@cfacorp/ctrl-platform-shared-react-components';
import { useAppTranslation, useHasDeliveryPerms } from './hooks';
import { initAmplitude, setIdentity } from './analytics/amplitude';

const AppRouter = lazy(() => import('./routes/AppRouter'));
initAmplitude();

enum DeliveryDataPoints {
  DeliveryRadius = 'delivery_radius',
}
const deliveryDataPointGroups = {
  [DeliveryDataPoints.DeliveryRadius]: 'Delivery Radius',
};

export default function Root() {
  const { t } = useAppTranslation();
  const { hasDeliveryPerms, permsLoaded } = useHasDeliveryPerms();
  const hasDeliveryAreaFeatureFlag = useHasFeatureFlag(
    FeatureFlags.DELIVERY_AREA,
  );

  const routes: RouteInfo[] = useMemo(
    () => [
      {
        route: `/delivery`,
        displayName: t('AppRoutes.deliveryArea'),
        keywords: [
          'CFA delivery',
          'delivery area',
          'deliver',
          'perimeter',
          'zone',
          'boundary',
          'driv',
          'distance',
          'map',
        ],
      },
    ],
    [t],
  );

  useEffect(() => {
    cartDataPointsGroups.next({
      ...cartDataPointsGroups.value,
      [AppNames.CFA_DELIVERY]: deliveryDataPointGroups,
    });
  }, []);

  useEffect(() => {
    if (permsLoaded && hasDeliveryAreaFeatureFlag) {
      userPermissions.next({
        ...userPermissions.value,
        hasDeliveryAccess: hasDeliveryPerms,
      });
      appLoadingStatuses.next({
        ...appLoadingStatuses.value,
        deliveryLoaded: true,
      });
      globalRoutes.next({
        routes: [...globalRoutes.value.routes, ...routes],
      });
      setIdentity();
    }
  }, [hasDeliveryPerms, permsLoaded, hasDeliveryAreaFeatureFlag, routes]);

  return (
    <Suspense fallback={null}>
      <AppRouter />
    </Suspense>
  );
}
