import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Typography,
} from '@cfa/react-components';
import styled from 'styled-components';
import { useAppTranslation } from '../../../hooks';

export const ErrorModal = ({
  handleErrorModalClose,
  isErrorModalOpen,
  modalTitle,
  modalBody,
}: {
  handleErrorModalClose: () => void;
  isErrorModalOpen: boolean;
  modalTitle: string;
  modalBody: string;
}) => {
  const { t } = useAppTranslation();
  // capitalize first letter of error message
  const text =
    modalBody.trim().charAt(0).toUpperCase() + modalBody.trim().slice(1);

  return (
    <Wrapper show={isErrorModalOpen} onClose={handleErrorModalClose} size="md">
      <Header align="left">{modalTitle}</Header>
      <Body>
        <Typography
          data-testid="modal-body"
          fontWeight="extralight"
          align="left"
        >
          {text}
        </Typography>
      </Body>
      <ModalFooter>
        <OkayButton
          onClick={handleErrorModalClose}
          variant="filled"
          color="primary"
        >
          {t('ErrorModal.errorButtonText')}
        </OkayButton>
      </ModalFooter>
    </Wrapper>
  );
};

const Wrapper = styled(Modal)`
  .cfa-modal-content {
    border-radius: 5px;
  }
  .cfa-modal-close-btn {
    display: none;
  }
`;
const Header = styled(ModalHeader)`
  padding: 2rem 0 0 2rem;
  font-size: 18px;
`;
const Body = styled(ModalBody)`
  padding: 0 0 0 2rem;
`;
const OkayButton = styled(Button)`
  border-radius: 5px;
  min-width: unset;
  padding: 0 50px;
  width: auto;
`;
