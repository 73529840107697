import {
  AreaType,
  GetDeliveryAreasRequest,
  TravelType,
} from '@bufteam/cfacorp_delivery.bufbuild_es/cfa/delivery/area/v1/area_service_pb';
import { useMemo, useState } from 'react';
import { Coordinates } from '@bufteam/cfacorp_delivery.bufbuild_es/cfa/delivery/core/v1/coordinates_pb';
import { deliveryAreaService } from '../services';
import {
  getLatLng,
  NO_ESTIMATE_OPTION,
} from '../containers/DeliveryArea/utils';
import { colors } from '../theme';
import { useGetStoreNumber } from './useGetStoreNumber';

export const useTimeEstimatesMapAndApi = ({
  setIsLoading,
  map,
}: {
  setIsLoading: (b: boolean) => void;
  map: google.maps.Map | null;
}) => {
  const locationNumber = useGetStoreNumber();

  const [polyline, setPolyline] = useState<google.maps.Polyline | null>();
  const [estimateCoordinates, setEstimateCoordinates] = useState<
    Coordinates[] | undefined
  >();

  const icons = useMemo(
    () => [
      {
        icon: {
          path: google.maps.SymbolPath.CIRCLE,
          fillOpacity: 1,
          scale: 1.1,
        },
        repeat: '5px',
        offset: '0',
      },
    ],
    [],
  );

  const reset = () => {
    if (polyline) {
      polyline.setMap(null);
    }
  };

  const updateMapRadius = (coordinates: Coordinates[]) => {
    reset();
    if (!coordinates?.length) {
      return;
    }
    const poly = new google.maps.Polyline({
      strokeColor: colors.redStroke,
      path: getLatLng(coordinates),
      strokeOpacity: 0,
      clickable: false,
      strokeWeight: 1,
      editable: false,
      zIndex: 4,
      icons,
    });
    setPolyline(poly);
    poly.setMap(map);
  };

  const queryTimeEstimate = async (
    travelType: TravelType,
    estimate: number,
  ) => {
    if (estimate === NO_ESTIMATE_OPTION) {
      setEstimateCoordinates(undefined);
      reset();
      return;
    }
    setIsLoading(true);
    const { deliveryAreas } = await deliveryAreaService.getDeliveryAreas(
      new GetDeliveryAreasRequest({
        areaType: AreaType.SUGGESTED,
        distanceMinutes: estimate,
        locationNumber,
        travelType,
      }),
    );
    setIsLoading(false);
    if (deliveryAreas) {
      const coordinates =
        deliveryAreas?.areas?.find(a => a.areaType === AreaType.SUGGESTED)
          ?.coordinates ?? [];
      setEstimateCoordinates(coordinates);
      updateMapRadius(coordinates);
    }
  };

  const hardResetTimeEstimate = () => {
    setEstimateCoordinates(undefined);
    reset();
  };

  return {
    setEstimateCoordinates,
    hardResetTimeEstimate,
    estimateCoordinates,
    queryTimeEstimate,
  };
};
