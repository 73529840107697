import { useEffect, useRef } from 'react';
import { useGetStoreNumber } from './useGetStoreNumber';

export const useOnStoreChange = ({
  resetMaxArea,
  resetSearch,
  resetDraft,
}: {
  resetMaxArea: () => void;
  resetSearch: () => void;
  resetDraft: () => void;
}): { storeNumber: string } => {
  const storeNumber = useGetStoreNumber();
  const previousStoreNumber = useRef(storeNumber);

  useEffect(() => {
    if (storeNumber && storeNumber !== previousStoreNumber.current) {
      previousStoreNumber.current = storeNumber;
      resetMaxArea();
      resetSearch();
      resetDraft();
    }
  }, [resetMaxArea, resetSearch, resetDraft, storeNumber]);

  return { storeNumber };
};
