import { useSelectedStoreInfo } from '@cfacorp/ctrl-platform-shared-react-components';
import { Coordinates } from '@bufteam/cfacorp_delivery.bufbuild_es/cfa/delivery/core/v1/coordinates_pb';
import { useEffect, useState } from 'react';

export const useStoreCoordinates = (): Coordinates | undefined => {
  const store = useSelectedStoreInfo();
  const { latitude, longitude } = store ?? {};
  const [storeCoordinates, setStoreCoordinates] = useState<
    Coordinates | undefined
  >();

  useEffect(() => {
    if (latitude && longitude) {
      setStoreCoordinates(new Coordinates({ latitude, longitude }));
    }
  }, [latitude, longitude]);

  return storeCoordinates;
};
