import { CartButtons } from '@cfacorp/ctrl-platform-shared-react-components';
import React from 'react';
import {
  AreaType,
  DeliveryArea,
  TravelType,
  UpdateDeliveryAreasResponse,
} from '@bufteam/cfacorp_delivery.bufbuild_es/cfa/delivery/area/v1/area_service_pb';
import { Coordinates } from '@bufteam/cfacorp_delivery.bufbuild_es/cfa/delivery/core/v1/coordinates_pb';
import { events } from '../analytics/events';

const DEFAULT_TRAVEL_TYPE = TravelType.DRIVE;

export const useCartButtonsContent = ({
  maxAreaCoordinates,
  updateDeliveryArea,
  draftCoordinates,
  isMaxAreaEdited,
  storeNumber,
  travelType,
  isCurrent,
  isLoading,
}: {
  maxAreaCoordinates?: Coordinates[];
  updateDeliveryArea: (
    da: DeliveryArea,
    areaType: AreaType,
    isPublish?: boolean,
  ) => Promise<UpdateDeliveryAreasResponse>;
  draftCoordinates: Coordinates[];
  travelType: TravelType | null;
  isMaxAreaEdited: boolean;
  storeNumber: string;
  isCurrent: boolean;
  isLoading: boolean;
}) => {
  const isDraftEdited = !!draftCoordinates.length && !isCurrent;

  const handlePublish = () => {
    const publishQueries: Promise<UpdateDeliveryAreasResponse>[] = [];
    if (isDraftEdited) {
      publishQueries.push(
        updateDeliveryArea(
          new DeliveryArea({
            travelType: travelType ?? DEFAULT_TRAVEL_TYPE,
            coordinates: draftCoordinates,
          }),
          AreaType.CURRENT,
          true,
        ),
      );
    }
    if (isMaxAreaEdited) {
      publishQueries.push(
        updateDeliveryArea(
          new DeliveryArea({
            travelType: travelType ?? DEFAULT_TRAVEL_TYPE,
            coordinates: maxAreaCoordinates,
          }),
          AreaType.MAX,
          true,
        ),
      );
    }
    events.buttonTap('PublishNow', storeNumber);
    return Promise.allSettled(publishQueries);
  };

  const handleAddToCart = () => {
    events.buttonTap('AddToCart', storeNumber);
    return updateDeliveryArea(
      new DeliveryArea({
        travelType: travelType ?? DEFAULT_TRAVEL_TYPE,
        coordinates: draftCoordinates,
      }),
      AreaType.DRAFT,
    );
  };

  const cartButtonsContent = (
    <CartButtons
      isPublishNowDisplayed={isMaxAreaEdited}
      // @ts-ignore
      handleAddToCart={handleAddToCart}
      // @ts-ignore
      handlePublishNow={handlePublish}
      storeNumber={storeNumber ?? ''}
      publishNowLoading={isLoading}
      addToCartLoading={isLoading}
      isDisplayed={isDraftEdited}
    />
  );

  return { cartButtonsContent };
};
