/* eslint "sort-keys": ["warn", "asc", {"caseSensitive": false, "natural": false, "minKeys": 2}] */
export default {
  AppRoutes: {
    deliveryArea: 'CFA Delivery Area',
    deliveryLogistics: 'Delivery Logistics',
    mainMenu: 'Main Menu',
  },
  Breadcrumbs: {
    deliveryArea: 'CFA Delivery Area',
    home: 'Home',
  },
  CartButtons: {
    addToCart: 'Add To Cart',
    publishNow: 'Publish Now',
  },
  DeliveryArea: {
    deleteCoordinates: 'Delete Coordinates',
    deleteInfoWindow: 'Delete',
    dragPointsToAdjust: `Drag the points to adjust your restaurant's CFA delivery area`,
    Map: {
      clearAddressMarker: 'Clear',
      estimatePlaceholder: 'None selected',
      IconsAlt: {
        bike: 'Bike icon',
        car: 'Car icon',
        walk: 'Walk icon',
      },
      searchAddress: 'Search an address',
      searchAddressPlaceholder: 'Enter address',
      selectedEstimateDetail: 'Shown as an orange dotted line',
      setAsDeliveryArea: 'Set as delivery area',
      showOnMapBtn: 'Show on map',
      showTimeEstimates: 'Show time estimates',
    },
    subTitle: 'Delivery area for {{ storeNumber }}',
    title: 'CFA Delivery Area',
  },
  ErrorModal: {
    errorButtonText: 'OK',
  },
  ErrorPage: {
    buttonText: 'Go to Home Screen',
    errorDescription: 'Please try again or contact the helpdesk',
    errorTitle: 'Something went wrong',
    maintenanceTitle: 'ControlPoint is currently down for maintenance',
  },
  NotFoundPage: {
    delivery: 'CFA Delivery',
    errorDescription: 'Error code 404',
    errorTitle: 'Page not found',
    help: 'Help',
    home: 'Home',
    kps: 'KPS',
    menu: 'Menu',
    ordering: 'Ordering',
    storeListing: 'Store Listing',
  },
};
