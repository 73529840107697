import { useSelectedStoreInfo } from '@cfacorp/ctrl-platform-shared-react-components';
import { useEffect, useState } from 'react';

export const useGetStoreNumber = () => {
  const store = useSelectedStoreInfo();
  const { storeNumber } = store ?? {};
  const [number, setNumber] = useState<string>('');

  useEffect(() => {
    if (storeNumber) {
      setNumber(storeNumber);
    }
  }, [storeNumber]);

  return number;
};
