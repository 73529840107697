import { Button, SearchDropdown, Typography } from '@cfa/react-components';
import React from 'react';
import styled from 'styled-components';
import { useAppTranslation } from '../../../hooks';
import { TEST_IDS } from '../../../data-test-ids';

const {
  CONTAINERS: {
    DELIVERY_AREA: {
      ADDRESS_SEARCH: { SEARCH_DROPDOWN, SHOW_BUTTON },
    },
  },
} = TEST_IDS;

export const AddressSearch = ({
  handleShowOnMap,
  setSearchAddress,
  searchAddress,
  resetSearch,
  bbox,
}: {
  setSearchAddress: (a?: google.maps.places.AutocompletePrediction) => void;
  searchAddress?: google.maps.places.AutocompletePrediction;
  bbox: google.maps.LatLngBounds | undefined;
  handleShowOnMap: (placeId: string) => void;
  resetSearch: () => void;
}) => {
  const { t } = useAppTranslation();
  const autocompleteService = new google.maps.places.AutocompleteService();

  const isAddressSelected = Boolean(searchAddress);

  const handleShowOnMapClick = () => {
    if (!searchAddress?.place_id) {
      console.error(
        'No place id available for the following address',
        searchAddress,
      );
      return;
    }
    handleShowOnMap(searchAddress.place_id);
  };
  const handleSearchPredictions = async (
    query: string,
    _: number,
    cursor: number,
  ) => {
    const baseResult = {
      canLoadMore: false,
      results: [],
      cursor,
    };
    if (!query.length) {
      return Promise.resolve(baseResult);
    }
    try {
      const res = await autocompleteService.getPlacePredictions({
        locationBias: bbox,
        input: query,
      });
      return {
        ...baseResult,
        results: res.predictions,
      };
    } catch (e) {
      console.error(`Could not load predictions for ${query}`, e);
      return baseResult;
    }
  };

  return (
    <InputContainer>
      <Typography>{t('DeliveryArea.Map.searchAddress')}</Typography>
      <StyledSearchDropdown
        renderOption={(o: google.maps.places.AutocompletePrediction) => ({
          textValue: o.description,
          rendered: o.description,
          id: o.place_id,
        })}
        onChange={(ap: google.maps.places.AutocompletePrediction) =>
          setSearchAddress(ap)
        }
        placeholder={t('DeliveryArea.Map.searchAddressPlaceholder')}
        onSearch={handleSearchPredictions}
        data-testid={SEARCH_DROPDOWN}
        value={searchAddress}
      />
      <StyledButton
        onClick={handleShowOnMapClick}
        disabled={!isAddressSelected}
        data-testid={SHOW_BUTTON}
        color="secondary"
      >
        {t('DeliveryArea.Map.showOnMapBtn')}
      </StyledButton>
      {isAddressSelected && (
        <ClearBtn color="secondary" onClick={resetSearch}>
          {t('DeliveryArea.Map.clearAddressMarker')}
        </ClearBtn>
      )}
    </InputContainer>
  );
};

const InputContainer = styled.div`
  align-items: baseline;
  flex-direction: row;
  margin-top: 22px;
  display: flex;
  width: 100%;
`;
const StyledSearchDropdown = styled(SearchDropdown)`
  margin-left: 18px;
  max-width: 395px;
  .cfa-search-dropdown-clear-button {
    display: none;
  }
`;
const StyledButton = styled(Button)`
  margin-left: 18px;
`;
const ClearBtn = styled(Typography)`
  margin-left: 18px;
  cursor: pointer;
`;
