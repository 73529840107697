import {
  DeliveryAreas,
  GetDeliveryAreasRequest,
  TravelType,
} from '@bufteam/cfacorp_delivery.bufbuild_es/cfa/delivery/area/v1/area_service_pb';
import { useEffect, useRef, useState } from 'react';
import { deliveryAreaService } from '../services';
import { useTravelTypeContext } from '../context/TravelTypeContext';
import { useGetStoreNumber } from './useGetStoreNumber';

const queryAreas = async (locationNumber: string, travelType: TravelType) => {
  if (!locationNumber) {
    console.error('Location number is not defined');
    return;
  }

  try {
    const response = await deliveryAreaService.getDeliveryAreas(
      new GetDeliveryAreasRequest({
        travelType: travelType,
        locationNumber,
      }),
    );
    const { deliveryAreas } = response;
    if (!deliveryAreas) {
      console.error('Delivery Areas undefined');
      return;
    }

    return deliveryAreas;
  } catch (e) {
    console.error('GetDeliveryAreas Error', e);
    return;
  }
};

export const useDeliveryAreas = () => {
  const locationNumber = useGetStoreNumber();
  const { travelType } = useTravelTypeContext();
  const abortController = useRef<AbortController | null>(null);
  const [deliveryAreas, setDeliveryAreas] = useState<DeliveryAreas | null>();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  // When location number or travel type changes fetch delivery areas
  useEffect(() => {
    if (!locationNumber) {
      console.error('Location number is not defined');
      return;
    }

    const fetchDeliveryAreas = async () => {
      // Start loading abort any previous fetch
      setIsLoading(true);
      if (abortController.current) {
        abortController.current.abort();
      }
      // Create a new abort controller for this fetch
      const controller = new AbortController();
      abortController.current = controller;

      // Fetch delivery areas.
      const areas = await queryAreas(locationNumber, travelType);
      // If the fetch was aborted, do not update state
      if (controller.signal.aborted) {
        return;
      }
      // Update state with delivery areas
      if (!areas) {
        setIsLoading(false);
        return;
      }

      setDeliveryAreas(areas);
      setIsLoading(false);
    };

    fetchDeliveryAreas();

    return () => {
      // Abort fetch on cleanup
      if (abortController.current) {
        abortController.current.abort();
      }
    };
  }, [abortController, locationNumber, travelType]);

  return { deliveryAreas, isLoading };
};
