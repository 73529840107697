import React from 'react';
import {
  AreaType,
  DeliveryArea,
  TravelType,
  UpdateDeliveryAreasResponse,
} from '@bufteam/cfacorp_delivery.bufbuild_es/cfa/delivery/area/v1/area_service_pb';
import { NO_ESTIMATE_OPTION } from '../containers/DeliveryArea/utils';
import { EstimationInputs } from '../containers/DeliveryArea/components';
import { useTravelTypeContext } from '../context/TravelTypeContext';
import { useTimeEstimateContext } from '../context/TimeEstimateContext';
import { events } from '../analytics/events';
import { useTimeEstimatesMapAndApi } from './useTimeEstimatesMapAndApi';
import { useGetStoreNumber } from './useGetStoreNumber';

export const useTimeEstimateContent = ({
  updateDeliveryArea,
  setIsLoading,
  map,
}: {
  updateDeliveryArea: (
    da: DeliveryArea,
    areaType: AreaType,
  ) => Promise<UpdateDeliveryAreasResponse>;
  setIsLoading: (b: boolean) => void;
  map: google.maps.Map | null;
}) => {
  const { travelType, setTravelType } = useTravelTypeContext();
  const { timeEstimate, setTimeEstimate } = useTimeEstimateContext();
  const storeNumber = useGetStoreNumber();

  const { queryTimeEstimate, hardResetTimeEstimate, estimateCoordinates } =
    useTimeEstimatesMapAndApi({
      setIsLoading,
      map,
    });

  const showSetAsDeliveryArea =
    Boolean(estimateCoordinates?.length) && Boolean(travelType);

  const handleTravelTypeChange = async (type: TravelType) => {
    if (type === travelType) {
      setTimeEstimate(NO_ESTIMATE_OPTION);
      hardResetTimeEstimate();
      setTravelType(TravelType.UNSPECIFIED);
      return;
    }
    setTravelType(type);
    await queryTimeEstimate(type, timeEstimate);
  };

  const handleEstimateChange = async (estimate: number) => {
    if (!travelType || estimate === NO_ESTIMATE_OPTION) {
      setTimeEstimate(NO_ESTIMATE_OPTION);
      hardResetTimeEstimate();
      return;
    }
    setTimeEstimate(estimate);
    setTravelType(travelType);
    await queryTimeEstimate(travelType, estimate);
    events.showTimeEstimate(storeNumber, timeEstimate, travelType);
  };

  const handleSetAsDeliveryArea = () => {
    if (estimateCoordinates) {
      setTimeEstimate(NO_ESTIMATE_OPTION);
      hardResetTimeEstimate();
      events.setAsDeliveryArea(storeNumber, timeEstimate, travelType);
      return updateDeliveryArea(
        new DeliveryArea({
          coordinates: estimateCoordinates,
          travelType: travelType,
        }),
        AreaType.DRAFT,
      );
    }
  };

  const estimateContent = (
    <EstimationInputs
      handleSetAsDeliveryArea={handleSetAsDeliveryArea}
      handleTravelTypeChange={handleTravelTypeChange}
      showSetAsDeliveryArea={showSetAsDeliveryArea}
      handleEstimateChange={handleEstimateChange}
      timeEstimate={timeEstimate}
      travelType={travelType}
    />
  );

  return { estimateContent, travelType };
};
