import {
  identify,
  init,
  reset,
  setUserId as setAmplitudeUserId,
  track,
} from '@amplitude/analytics-browser';
import { BrowserOptions } from '@amplitude/analytics-types';
import { Identify } from '@amplitude/analytics-core';
import env from '../env';
import { OktaTokenStorage } from '../services';

export const initAmplitude = () => {
  const options: BrowserOptions = { defaultTracking: true };

  init(env.REACT_APP_AMPLITUDE_API_KEY ?? '', undefined, {
    ...options,
  });
};

export const setUserId = (userId: string) => setAmplitudeUserId(userId);

export const setIdentity = () => {
  const tokenInfo: OktaTokenStorage = JSON.parse(
    localStorage.getItem('okta-token-storage') ?? '',
  );

  setUserProperty('application', 'Control Point - Delivery Area Tool');
  setUserProperty('environment', env.REACT_APP_ENVIRONMENT);
  const userProps = tokenInfo?.idToken?.claims;
  setUserProperty('username', userProps.name);
  setUserId(userProps.employeeNumber);
};

export const setUserProperty = (key: string, value: string | string[]) => {
  const identifyObj = new Identify();
  identifyObj.set(key, value);
  identify(identifyObj);
};

export const resetUser = () => reset();

interface EventInformation {
  name: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  attributes?: any; // allows for ultimate flexibility when sending event properties
}
export const logEvent = ({ name, attributes }: EventInformation): void => {
  track(name, { ...attributes });
};
