import {
  Button,
  Dropdown,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from '@cfa/react-components';
import React, { useCallback } from 'react';
import styled from 'styled-components';
import { TravelType } from '@bufteam/cfacorp_delivery.bufbuild_es/cfa/delivery/area/v1/area_service_pb';
import CarIcon from '../../../icons/Car.svg';
import BikeIcon from '../../../icons/Bike.svg';
import WalkIcon from '../../../icons/Walk.svg';
import { useAppTranslation } from '../../../hooks';
import { TEST_IDS } from '../../../data-test-ids';
import { formatMinutes, TIME_ESTIMATE_OPTIONS } from '../utils';

const { DROPDOWN } = TEST_IDS.CONTAINERS.DELIVERY_AREA.ESTIMATION_INPUTS;
const { WALK, DRIVE, BIKE } = TravelType;
const DM = `DeliveryArea.Map`;

export const EstimationInputs = ({
  handleSetAsDeliveryArea,
  handleTravelTypeChange,
  showSetAsDeliveryArea,
  handleEstimateChange,
  timeEstimate,
  travelType,
}: {
  handleTravelTypeChange: (type: TravelType) => void;
  handleEstimateChange: (estimate: number) => void;
  handleSetAsDeliveryArea: () => void;
  showSetAsDeliveryArea: boolean;
  travelType: TravelType | null;
  timeEstimate: number;
}) => {
  const { t } = useAppTranslation();

  const createDeliveryMethod = useCallback(
    (type: TravelType, icon: string, alt: string) => (
      <DeliveryMethod onClick={() => handleTravelTypeChange(type)} value={type}>
        <img src={icon} alt={`${alt}`} />
      </DeliveryMethod>
    ),
    [handleTravelTypeChange],
  );

  const drive = createDeliveryMethod(DRIVE, CarIcon, t(`${DM}.IconsAlt.car`));
  const bike = createDeliveryMethod(BIKE, BikeIcon, t(`${DM}.IconsAlt.bike`));
  const walk = createDeliveryMethod(WALK, WalkIcon, t(`${DM}.IconsAlt.walk`));

  return (
    <InputContainer>
      <Typography>{t(`${DM}.showTimeEstimates`)}</Typography>
      <EstimateContainer>
        <EstimateSelector
          placeholder={t(`${DM}.estimatePlaceholder`)}
          options={TIME_ESTIMATE_OPTIONS}
          onChange={handleEstimateChange}
          renderOption={formatMinutes}
          data-testid={DROPDOWN}
          disabled={!travelType}
          value={timeEstimate}
        />
        <TimeEstimateSubText>
          {t(`${DM}.selectedEstimateDetail`)}
        </TimeEstimateSubText>
      </EstimateContainer>
      <DeliveryMethodGroup value={travelType}>
        {drive}
        {bike}
        {walk}
      </DeliveryMethodGroup>
      {showSetAsDeliveryArea ? (
        <SetDeliveryArea
          onClick={handleSetAsDeliveryArea}
          variant="outlined"
          color="secondary"
        >
          {t(`${DM}.setAsDeliveryArea`)}
        </SetDeliveryArea>
      ) : null}
    </InputContainer>
  );
};

const InputContainer = styled.div`
  align-items: baseline;
  flex-direction: row;
  margin-bottom: 22px;
  display: flex;
  width: 100%;
`;
const SetDeliveryArea = styled(Button)`
  background-color: transparent;
  border-radius: 25px;
  margin-left: 18px;
`;
const TimeEstimateSubText = styled(Typography)`
  font-style: italic;
  font-size: 12px;
`;
const EstimateContainer = styled.div`
  margin-left: 18px;
`;
const DeliveryMethodGroup = styled(ToggleButtonGroup)`
  align-self: flex-start;
  margin: 0 0 0 18px;
  height: 51px;
`;
const DeliveryMethod = styled(ToggleButton)`
  &:disabled {
    pointer-events: auto;
    cursor: not-allowed;
  }
  height: 49px;
`;
const EstimateSelector = styled(Dropdown)`
  width: 286px;
`;
