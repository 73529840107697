import { useEffect } from 'react';
import {
  AppNames,
  deleteCartDataPoints,
} from '@cfacorp/ctrl-platform-ui-core-utils';
import {
  AreaType,
  DeliveryArea,
  TravelType,
  UpdateDeliveryAreasResponse,
} from '@bufteam/cfacorp_delivery.bufbuild_es/cfa/delivery/area/v1/area_service_pb';
import { filter } from 'rxjs';
import { useGetStoreNumber } from './useGetStoreNumber';

export const useOnDeleteCart = ({
  updateDeliveryArea,
  resetDraft,
  travelType,
}: {
  updateDeliveryArea: (
    da: DeliveryArea,
    areaType: AreaType,
  ) => Promise<UpdateDeliveryAreasResponse>;
  travelType: TravelType | null;
  resetDraft: () => void;
}) => {
  const storeNumber = useGetStoreNumber();

  useEffect(() => {
    const deleteSub = deleteCartDataPoints
      .pipe(
        filter(
          cart =>
            cart.appName === AppNames.CFA_DELIVERY &&
            !!cart.dataPoints.length &&
            !!storeNumber.length,
        ),
      )
      .subscribe(() => {
        (async () => {
          await updateDeliveryArea(
            new DeliveryArea({
              travelType: travelType ?? TravelType.DRIVE,
              coordinates: [],
            }),
            AreaType.DRAFT,
          );
          // trigger useDraftArea to get latest data
          resetDraft();
          deleteCartDataPoints.next({
            dataPoints: [],
            appName: null,
          });
        })();
      });
    return () => {
      deleteSub.unsubscribe();
    };
  }, [resetDraft, storeNumber.length, travelType, updateDeliveryArea]);
};
