import {
  AreaType,
  DeliveryArea,
  UpdateDeliveryAreasRequest,
} from '@bufteam/cfacorp_delivery.bufbuild_es/cfa/delivery/area/v1/area_service_pb';
import { deliveryAreaService } from '../services';
import { useGetStoreNumber } from './useGetStoreNumber';

export const useUpdateAreaQueries = ({
  setIsCurrent,
  setIsLoading,
  resetMaxArea,
  resetDraft,
  setError,
}: {
  setIsCurrent: (b: boolean) => void;
  setIsLoading: (b: boolean) => void;
  setError: (b: string) => void;
  resetMaxArea: () => void;
  resetDraft: () => void;
}) => {
  const locationNumber = useGetStoreNumber();

  const updateDeliveryArea = (
    da: DeliveryArea,
    areaType: AreaType,
    isPublish = false,
  ) => {
    setIsCurrent(isPublish);
    setIsLoading(true);

    return deliveryAreaService
      .updateDeliveryAreas(
        new UpdateDeliveryAreasRequest({
          deliveryAreas: {
            locationNumber,
            areas: [
              new DeliveryArea({
                ...da,
                areaType,
              }),
            ],
          },
        }),
      )
      .catch(e => {
        setError(e.message?.split(':')?.[1] ?? 'An error occurred');
        throw e;
      })
      .finally(() => {
        // reset max area to trigger max area query and see latest max area
        if (areaType === AreaType.MAX) {
          resetMaxArea();
          setIsLoading(false);
          return;
        }
        // triggers logic in useDraftArea's useEffect to get latest draft data
        resetDraft();
        setIsLoading(false);
      });
  };

  return { updateDeliveryArea };
};
