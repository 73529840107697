export const TEST_IDS = {
  CONTAINERS: {
    DELIVERY_AREA: {
      ADDRESS_SEARCH: {
        SEARCH_DROPDOWN: 'address-search-dropdown',
        SHOW_BUTTON: 'show-on-map-button',
      },
      ESTIMATION_INPUTS: {
        DROPDOWN: 'time-estimate-dropdown',
      },
    },
  },
};
